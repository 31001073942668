import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useRouteError
} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { HoneybadgerErrorBoundary } from '@honeybadger-io/react'

import { theme as pangaeaTheme } from '@smallworld-io/pangaea'
import { AuthProvider, AuthGuard, QualitySuperConnector } from '~/auth'
import { ProtectedLayout, UnprotectedLayout } from '~/layouts'
import {
  CompanySavedRelationshipLeads,
  CompetitorCompanies,
  ErrorPage,
  Home,
  IntroductionDetails,
  IntroductionRequest,
  IntroductionsList,
  MyNetwork,
  RateRelationships,
  RelationshipLeads,
  Discover,
  SavedRelationshipLeads,
  Settings,
  TargetCompanies,
  Login
} from '~/pages'
import '@fontsource-variable/montserrat'
import '@fontsource-variable/roboto-mono'
import Header from './components/Header'
import Footer from './components/Footer'
import { ProspectSearch } from './pages/ProspectSearch'
import { honeybadger } from 'settings'

const tanstackQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: true
    }
  }
})

const ErrorRethrow = () => { // Router captures errors, rethrow to get Honeybadgered
  const error = useRouteError()
  console.dir(error)
  throw error
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Routes users can access only if they are authenticated */}
      <Route element={<AuthGuard />} errorElement={<ErrorRethrow />}>
        <Route element={<ProtectedLayout />}>
          <Route path="/leads" element={<RelationshipLeads />} />
          <Route path="/rate-relationships" element={<RateRelationships />} />
          <Route path="/competitor-companies" element={<CompetitorCompanies />} />
          <Route path="/target-companies" element={<TargetCompanies />} />
          <Route path="/saved-leads">
            <Route path=":slug" element={<CompanySavedRelationshipLeads />} />
            <Route path="" element={<SavedRelationshipLeads />} />
            <Route path="*" element={<SavedRelationshipLeads />} />
          </Route>
          <Route path="/settings" element={<Settings />} />
          <Route path="/my-network" element={<MyNetwork />} />
          {/* all routes that require 100 rating below */}
          <Route element={<QualitySuperConnector />}>
            <Route
              element={
                <>
                  <Header />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route path="/introduction-request/:id" element={<IntroductionRequest />} />
              <Route path="/introductions/:id" element={<IntroductionDetails />} />
              <Route path="/introductions" element={<IntroductionsList />} />
            </Route>
            <Route path="/discover" element={<Discover />} />
            <Route path="/search" element={<ProspectSearch />} />
          </Route>
          {/* all routes that require 100 rating above */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
      </Route>

      {/* Routes users can access without being authenticated */}
      <Route element={<UnprotectedLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>
    </>
  )
)

export const App = () => {
  return (
    <HelmetProvider>
      <ChakraProvider theme={extendTheme(pangaeaTheme)}>
        <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPage}>
          <QueryClientProvider client={tanstackQueryClient}>
            <AuthProvider>
              <RouterProvider router={router} />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </AuthProvider>
          </QueryClientProvider>
        </HoneybadgerErrorBoundary>
      </ChakraProvider>
    </HelmetProvider>
  )
}

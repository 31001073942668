import { useMemo, useEffect } from 'react'
import { AuthContext } from '~/auth'
import { useSessionCheckQuery } from '~/api/auth'
import { honeybadger } from '~/settings'

type AuthProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { data, isLoading, isSuccess } = useSessionCheckQuery()
  const { personId, accountId, userId, organizationId } = {
    personId: null, accountId: null, userId: null, organizationId: null,
    ...data
  }

  useEffect(() => {
    if (!isSuccess) return

    honeybadger.setContext({
      person_id: personId || 'X',
      account_id: accountId || 'X',
      user_id: userId || 'X',
      organization_id: organizationId || 'X',
    })

  }, [
    isSuccess,
    userId,
    organizationId,
    personId,
    accountId,
  ])

  const authContextData = useMemo(
    () => ({
      userId: userId,
      organizationId: organizationId,
      personId: personId,
      accountId: accountId,
      isLoggedIn: !!personId && !!accountId,
      isLoading: isLoading,
    }),
    [
      userId,
      organizationId,
      personId,
      accountId,
      isLoading,
    ]
  )

  return <AuthContext.Provider value={authContextData}>{props.children}</AuthContext.Provider>
}

import { useEffect } from 'react'
import { LOGIN_URL } from '~/settings'

export const Login = () => {
  useEffect(() => {
    window.location.href = LOGIN_URL
  }, [])

  return <></>
}

import { useQuery } from '@tanstack/react-query'
import { request } from './utils'
import { API_PATH } from '~/settings'

const BASE_URL = `${import.meta.env.VITE_RAILS_URL}${API_PATH}`

export type Credentials = {
  login: string
  password: string
}

export type AccessTokenData = {
  userId?: number
  organizationId?: number
  personId?: number
  accountId?: number
  issued: boolean
}

type SessionData = {
  isLoggedIn: boolean
  personId: number
  accountId: number
  userId?: number | null
  organizationId?: number | null
}

type SessionCheckResponse = {
  ok: boolean,
  data: SessionData | null
}

const sessionCheckUrl = new URL(`${BASE_URL}/session/check`)
export const useSessionCheckQuery = () => {
  return useQuery({
    queryKey: ['sessioncheck'],
    queryFn: () => {
      return request<null, SessionCheckResponse>(sessionCheckUrl)
    },
    select: (response: SessionCheckResponse) => {
      const { ok, data } = response
      return { isloggedIn: ok, ...data }
    },
    retry: 1,
  })
}

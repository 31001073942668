import { StarIcon } from '@chakra-ui/icons'
import { Box, Grid, GridItem, Center, HStack, Text } from '@chakra-ui/react'
import { BackButton, RequestIntroIcon, HideIcon } from '@smallworld-io/pangaea'
import { CircularBadge } from '~/components'
import { StrengthDisplay } from '~/components/RatingModule/StrengthDisplay'
import { railsUrl } from '~/lib'
import rlqEducation from '~/assets/rlq-education.png'
import { RelationshipLeadStore } from '~/stores'
import { useLoggedInUserQuery } from '~/api'
import { isEnterpriseUser } from '~/lib/userHelpers'

interface Props {
  overlayVisible: boolean
  windowCanSupportOverlay: boolean
  toggleOverlay: () => void
  topPosition: number
  leftPosition: number
}

export default function RelationshipLeadsHeader(props: Props) {
  const leadsStore = RelationshipLeadStore.useStore((state) => state)

  const userQuery = useLoggedInUserQuery()
  const { accountId, currentMembership, user } = userQuery.data

  function handleLeadsViewSwitch() {
    leadsStore.setShowingEnterpriseLeads(!leadsStore.showingEnterpriseLeads)
  }

  const myLeadsLength = RelationshipLeadStore.findByState(leadsStore.relationshipLeads, 'queued').length
  const unassignedLeadsLength = RelationshipLeadStore.findByState(leadsStore.unassignedLeads, 'queued').length
  const totalLeads = myLeadsLength + unassignedLeadsLength

  return (
    <>
      <Box
        display={props.overlayVisible && props.windowCanSupportOverlay ? '' : 'none'}
        position="absolute"
        height="100%"
        width="100%"
        top="0"
        left="0"
        zIndex="9999"
        onClick={props.toggleOverlay}
      >
        <img
          src={rlqEducation}
          style={{
            position: 'absolute',
            top: `${props.topPosition}px`,
            left: `${props.leftPosition}px`,
            zIndex: '999',
            boxShadow: '0 0 0 800px rgba(0,0,0,.785)' // Massive shadow hack
          }}
        />
      </Box>

      {isEnterpriseUser(currentMembership) && (
        <Grid templateColumns="repeat(2, 1fr)" m={4} gap={4}>
          <GridItem colSpan={1} textAlign="left">
            <BackButton onClick={() => (window.location.href = railsUrl())}>Home</BackButton>
          </GridItem>

          <GridItem colSpan={1} textAlign="right" />
        </Grid>
      )}

      <Center>
        <HStack spacing={4} /* mt={marginTop}*/>
          <Text as="h1" fontSize="4xl" textAlign="center">
            Your Relationship Leads
          </Text>

          <CircularBadge
            height="28px"
            width="28px"
            bgColor="red.400"
            textColor="white"
            text={totalLeads > 99 ? '99+' : String(totalLeads)}
          />
        </HStack>
      </Center>
      {/* </MotionBox> */}

      <Box color="gray.600" textAlign="center">
        These are the newest <StrengthDisplay rating="Very Strong" /> and <StrengthDisplay rating="Strong" />{' '}
        <Text as="span" fontWeight="bold">
          Relationships
        </Text>{' '}
        that Connectors have with Prospects that work at your Target Companies. Clear out your queue by{' '}
        <StarIcon fill="none" color="gray.300" boxSize={5} mt={-0.5} mr={1} ml={1} />{' '}
        <Text as="span" fontWeight="bold">
          Saving the Relationship
        </Text>
        , <RequestIntroIcon fill="none" color="gray.300" boxSize={5} mt={-0.5} mr={1} ml={1} />{' '}
        <Text as="span" fontWeight="bold">
          Requesting an Introduction
        </Text>{' '}
        or <HideIcon color="gray.300" boxSize={6} mt={0.5} ml={1} />{' '}
        <Text as="span" fontWeight="bold">
          Dismissing the Relationship
        </Text>
        .
        {!!accountId && !!user?.id && (
          <Box mt={1} mb={2}>
            <Text as="span" color="blue.400" cursor="pointer" onClick={handleLeadsViewSwitch}>
              {leadsStore.showingEnterpriseLeads && 'View Super Connector Relationship Leads'}
              {!leadsStore.showingEnterpriseLeads && 'View Enterprise Relationship Leads'}
            </Text>
          </Box>
        )}
      </Box>
    </>
  )
}

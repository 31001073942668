import { HideIcon, RequestIntroIcon, StarIcon } from '@smallworld-io/pangaea'
import { RelationshipCardButton, RelationshipCardButtonProps } from './RelationshipCardButton'
import { AssignIcon } from './AssignIcon'
import { Flex } from '@chakra-ui/react'

type Props = {
  onHide: () => void
  onSave: () => void
  onUnsave?: () => void
  onRequest: () => void
  onAssign?: () => void
  saved?: boolean
}

export const RelationshipCardButtons = (props: Props) => {
  const { onHide, onSave, onUnsave, onRequest, onAssign, saved } = props
  const showAssignButton = onAssign !== undefined

  type ButtonDefinition = Omit<RelationshipCardButtonProps, 'buttonIndex' | 'totalButtons'>

  const buttonDefinitions: ButtonDefinition[] = [
    {
      onClick: onHide,
      label: 'Dismiss Relationship',
      color: 'gray.600',
      icon: <HideIcon boxSize={8} color="gray.400" mt={1.5} />
    },
    {
      onClick: saved ? onUnsave : onSave,
      label: saved ? 'Unsave Relationship' : 'Save Relationship',
      color: 'yellow.400',
      icon: <StarIcon boxSize={6} color={saved ? 'yellow.400' : 'gray.400'} fill={saved ? 'yellow.400' : 'none'} />
    },
    {
      onClick: onRequest,
      label: 'Request Introduction',
      color: 'blue.400',
      icon: <RequestIntroIcon boxSize={6} color="gray.400" fill="none" />
    }
  ]

  if (showAssignButton) {
    buttonDefinitions.push({
      onClick: onAssign,
      label: 'Assign Lead',
      color: 'green.400',
      icon: <AssignIcon boxSize={6} color="gray.400" fill="none" />
    })
  }

  return (
    <Flex
      direction="row"
      alignItems="stretch"
      minH={47}
      bg="gray.50"
      borderBottomRightRadius={3}
      borderBottomLeftRadius={3}
      borderTopColor="gray.200"
      borderTopWidth="1px"
      borderTopStyle="solid"
    >
      {buttonDefinitions.map((buttonDefinition, index) => (
        <RelationshipCardButton
          key={index}
          buttonIndex={index}
          totalButtons={buttonDefinitions.length}
          {...buttonDefinition}
        />
      ))}
    </Flex>
  )
}

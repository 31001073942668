import { AuthApi, HttpError } from '~/api'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export type RequestConfig<T> = {
  method?: HttpMethod
  headers?: HeadersInit
  body?: T | null
  defaultResponse?: T
  signal?: AbortSignal
}

export async function request<TRequest = unknown, TResponse = unknown>(
  url: URL,
  httpMethod: HttpMethod = 'GET',
  requestOptions?: RequestConfig<TRequest>,
  defaultResponse?: TResponse,
  timeout: number = 5000
): Promise<TResponse | null> {
  const { method = httpMethod, headers = {}, body = null, signal = null } = requestOptions || {}

  const abortController = new AbortController()
  const timeoutId = setTimeout(() => abortController.abort(), timeout)

  const fetchOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    signal,
    mode: 'cors',
    credentials: 'include',
    body: body ? JSON.stringify(body) : null
  }

  //
  // FIX: This is all quite useless, tan-stack does a much beter job
  //
  try {
    let response = await fetch(url, fetchOptions)

    // Clear the timeout if the fetch request resolves in time
    clearTimeout(timeoutId)

    if (!response.ok || response.status === 400 || response.status === 404) {
      throw new HttpError(response.status, response.statusText)
    }

    if (response.status === 204) {
      // We check explicitly for 204 because we try to parse the JSON payload
      // in below steps. If there's no content returned, then the JSON parsing
      // step throws an exception (which we now avoid with this step).
      return null
    } else {
      try {
        const clonedResponse = response.clone()
        return await clonedResponse.json()
      } catch (error) {
        const clonedResponse = response.clone()
        const text = await clonedResponse.text()
        const message = error.message.concat('\n\n').concat(text)
        throw new HttpError(response.status, response.statusText, message)
      }
    }
  } catch (error) {
    clearTimeout(timeoutId) // clear the timeout if an error occurred

    if (defaultResponse) {
      return defaultResponse
    } else {
      throw error
    }
  }
}

import { useCallback, useState } from 'react'
import { Box, Flex, Select, Text, Textarea } from '@chakra-ui/react'
import { HideIcon } from '@smallworld-io/pangaea'
import { UndoButton } from './UndoButton'

export type HideRelationshipContentProps = {
  firstName: string
  lastName: string
  onBack?: () => void
  onSelect?: (reason: RelationshipLeadDismissedReasons) => void
}

export const HideRelationshipContent = ({ onBack, onSelect, firstName, lastName }: HideRelationshipContentProps) => {
  const [selectedOption, setSelectedOption] = useState(null)

  const onSelection = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value }
      } = event
      setSelectedOption(value)
      onSelect(value as RelationshipLeadDismissedReasons)
    },
    [setSelectedOption, onSelect]
  )

  const usingSelect = (
    <Select noOfLines={1} placeholder="Choose a reason" onChange={onSelection}>
      <option value="not_relevant">Relationship not relevant at this time</option>
      <option value="existing_path">Already have a path of engagement</option>
      <option value="not_senior">Relationship is not senior enough</option>
    </Select>
  )

  const usingText = <Textarea placeholder="Type a reason" size="sm" />

  const inputMethodForReason = selectedOption === 'other' ? usingText : usingSelect

  return (
    <Flex direction="column" flex="1" justify="center" align="center" width="100%" position="relative">
      {onBack && <UndoButton onClick={onBack} />}

      <Flex direction="column" flex="2" justifyContent="center" alignItems="center" p={4} width="100%">
        <Text fontSize="xs" align="center" fontWeight="bold" lineHeight=".7rem" mb={1}>
          <HideIcon boxSize={5} color="black" mr={1} mt={0.5} />
          {firstName} {lastName}{' '}
          <Box as="span" fontWeight="normal" whiteSpace="nowrap">
            was Dismissed
          </Box>
        </Text>
        {onSelect && inputMethodForReason}
      </Flex>
    </Flex>
  )
}

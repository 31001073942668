import { useEffect } from 'react'
import { useNavigate, useOutlet, useLocation } from 'react-router-dom'
import { useAuth } from '~/auth'
import { useLoggedInUserQuery } from '~/api'
import { Center } from '@chakra-ui/react'
import { LoadingSpinner } from '~/components'
import { LOGIN_URL } from '~/settings'

export const AuthGuard = () => {
  const navigate = useNavigate()
  const outlet = useOutlet()
  const location = useLocation()

  const { isLoggedIn, isLoading, userId, personId } = useAuth()
  const { isSuccess: userIsSuccess } = useLoggedInUserQuery()
  const isLeadsRoute = location.pathname.includes('leads') // HACK ALERT!!!

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      window.location.href = LOGIN_URL
    }
  }, [isLoggedIn, isLoading])

  useEffect(() => {
    if (!isLoading && personId && !userId && isLeadsRoute) {
      // HACK ALERT!!!
      navigate(location.pathname)
    }
  }, [isLoggedIn, personId, userId, isLeadsRoute])

  if ((!isLoggedIn || !userIsSuccess) && !isLeadsRoute) {
    // HACK ALERT!!!
    return (
      <Center minH="100vh">
        <LoadingSpinner />
      </Center>
    )
  }

  return outlet
}
